import { CONFIG } from "@meili/config"

import PropTypes from "propTypes"

import usePageTitle from "hooks/usePageTitle"
import useAppLoaded from "hooks/useAppLoaded"

import ChunkErrorMessage from "./ChunkErrorMessage"
import ErrorMessage from "./ErrorMessage"

function ErrorBoundaryFallback(props) {
  const { chunkError, displayError, error, warning } = props

  const errorName = error?.name || warning?.code || "Unknown"
  usePageTitle({ title: `errorBoundary.${errorName}` })
  useAppLoaded()

  if (
    chunkError &&
    CONFIG.REACT_APP_TO_USE !== "Connect" &&
    error?.name === "ChunkLoadError"
  ) {
    return (
      <ChunkErrorMessage message="This application has been updated, please refresh your browser to see the latest content." />
    )
  }
  return !displayError ? null : <ErrorMessage {...props} />
}

ErrorBoundaryFallback.propTypes = {
  displayError: PropTypes.bool,
  chunkError: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string
  }),
  warning: PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    isCritical: PropTypes.bool
  })
}

ErrorBoundaryFallback.defaultProps = {
  displayError: true,
  chunkError: false,
  error: undefined,
  warning: undefined
}

export default ErrorBoundaryFallback
