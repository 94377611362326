import { useEffect } from "react"

import root from "root"

function useAppLoaded() {
  useEffect(() => {
    root.setAttribute("class", "mli-loaded")
  }, [])
}

export default useAppLoaded
