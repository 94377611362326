/* eslint-disable react/no-unused-state */
import { Component, useCallback, useEffect } from "react"

import ErrorBoundaryFallback from "./ErrorBoundaryFallback"
import type { Warning } from "./UIMonitor"
import { useUIMonitor } from "./UIMonitor"

type ErrorBoundaryProps = React.PropsWithChildren<{
  chunkError?: boolean
  displayError?: boolean
  warning?: Warning | null
  onError: (error: Error) => void
}>

type ErrorBoundaryState = {
  hasError: boolean
  error: null | Error
}

class ClassErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error) {
    const { onError } = this.props
    onError(error)
  }

  render() {
    const { hasError, error } = this.state
    const { children, warning, ...otherProps } = this.props
    if (hasError || warning?.isCritical) {
      return (
        <ErrorBoundaryFallback
          error={error}
          warning={warning}
          {...otherProps}
        />
      )
    }

    return children
  }
}

export default function ErrorBoundary({
  children,
  chunkError = false,
  displayError = true
}: Readonly<Omit<ErrorBoundaryProps, "onError">>) {
  const { monitor, warning } = useUIMonitor()

  const onError = useCallback(
    (error: Error) => {
      monitor?.captureException(error, {
        name: error.name || "GenericError"
      })
    },
    [monitor]
  )

  useEffect(() => {
    monitor?.startView("")
  }, [monitor])

  return (
    <ClassErrorBoundary
      warning={warning}
      onError={onError}
      chunkError={chunkError}
      displayError={displayError}
    >
      {children}
    </ClassErrorBoundary>
  )
}
