import { CONFIG } from "@meili/config"

import {
  localStorageLoadState,
  sessionStorageLoadState
} from "localStorage/utils"

const PREFIX = "meili."

function sendDataToTaggingAPI(eventData) {
  if (
    eventData.meiliCustomerId &&
    eventData.meiliSessionId &&
    eventData.event.startsWith(PREFIX)
  ) {
    fetch(CONFIG.REACT_APP_TAGGING_API, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ptid: eventData.ptid,
        meiliCustomerId: eventData.meiliCustomerId,
        meiliSessionId: eventData.meiliSessionId,
        event: eventData.event.substring(PREFIX.length),
        fid: eventData.funnelId,
        timestamp: eventData.timestamp,
        query: eventData.query
      })
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }
}

export function pushToTagging(ptid, eventName, monitor, funnelId, query) {
  const meiliSession = sessionStorageLoadState("meili-session")
  const meiliCustomer = localStorageLoadState("meili-customer")

  sendDataToTaggingAPI({
    event: `${PREFIX}${eventName}`,
    meiliSessionId: meiliSession?.id,
    meiliCustomerId: meiliCustomer?.id,
    ptid,
    query,
    funnelId,
    timestamp: Date.now()
  })

  try {
    monitor?.addCustomEvent(`${PREFIX}${eventName}`, {
      name: eventName,
      meiliSessionId: meiliSession?.id,
      meiliCustomerId: meiliCustomer?.id,
      ptid,
      appType: CONFIG.REACT_APP_TO_USE?.toLowerCase(),
      query,
      funnelId,
      timestamp: Date.now()
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error sending data to tagging", error)
  }
}

export default pushToTagging
