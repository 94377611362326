import { createContext, ReactNode, useContext, useMemo } from "react"
import PropTypes from "propTypes"

import { CONFIG } from "@meili/config"
import { isFulfilled } from "@meili/services/api/utils"

import useElementSizeType from "ui/responsive/useElementSizeType"
import { getWidgetResponsiveClasses } from "ui/responsive/enums"

import usePartnerContent from "merchandising/usePartnerContent"

import { usePartnerConfig } from "features/config/usePartnerConfig"

type PartnerContent = {
  connectBanner?: string
  homepageBanner?: string
  homepagePreBody?: string
  homepageBody?: string
  checkoutPostFormContent?: string
  crossSellBanner?: string
  searchFormFooter?: string
  searchFormHeader?: string
  confirmationPageContent?: string
  translations?: { [key: string]: string }
  noResultsContent?: string
  resultsBanner?: string
  promoTerms?: {
    [key: string]: string
  }
  mmbRetrieveBanner?: string
}

export type Config = Record<string, string | boolean>

type AppSizes = {
  setRef?: () => void
  widthClassName?: string
  heightClassName?: string
}

/**
 * Uses a list of prefixes to construct an object of content strings where airline loyalty tier
 * content is preferred. Falls back to default content if present.
 *
 * @param content an object containing all of the content
 * @param contentNamePrefixes an array of strings. Each string will be used as a prefix to get the
 * corresponding content e.g. `['foo', 'bar']` could get `content.fooAirlineLoyaltyAccountTier` and
 * `content.bar`
 * @param accountTier the user's account tier
 */
export function getLoyaltyContent<T extends string>(
  content: Record<string, string | undefined>,
  contentNamePrefixes: T[],
  accountTier?: string
) {
  const contentObj = contentNamePrefixes.reduce(
    (prev, contentNamePrefix) => ({
      ...prev,
      [contentNamePrefix]: accountTier
        ? content[
            `${contentNamePrefix}AirlineLoyaltyAccountTier-${accountTier}`
          ] ??
          content[`${contentNamePrefix}AirlineLoyaltyAccountTier`] ??
          content[contentNamePrefix]
        : content[contentNamePrefix]
    }),
    {} as Record<T, string | undefined>
  )

  return contentObj
}

function initPartnerContentContext(): {
  content: PartnerContent
  config: Config
  debug: boolean
  appSizes: AppSizes
} {
  const content: PartnerContent = {
    connectBanner: "",
    homepageBanner: "",
    homepagePreBody: "",
    homepageBody: "",
    checkoutPostFormContent: "",
    crossSellBanner: "",
    searchFormFooter: "",
    searchFormHeader: "",
    translations: undefined,
    confirmationPageContent: "",
    noResultsContent: "",
    mmbRetrieveBanner: "",
    promoTerms: undefined
  }
  const config: Config = {
    status: "pending",
    styleContent: "",
    ...CONFIG
  }
  const debug = false

  return {
    content,
    config,
    debug,
    appSizes: {}
  }
}

const PartnerContentContext = createContext(initPartnerContentContext())

export function usePartnerContentContext() {
  return useContext(PartnerContentContext)
}

export function useDebug() {
  const context = useContext(PartnerContentContext)

  return context?.debug
}

export function PartnerContentProvider({
  children
}: {
  readonly children: ReactNode
}) {
  const content = usePartnerContent()
  const config = usePartnerConfig() as Config

  const { setRef, sizeWidthType, sizeHeightType } = useElementSizeType()

  const [widthClassName, heightClassName] = getWidgetResponsiveClasses(
    sizeWidthType,
    sizeHeightType
  )

  const debug = window?.location?.origin === config.REACT_APP_DEBUG_ORIGIN

  const value: {
    content: PartnerContent
    config: Config
    debug: boolean
    appSizes: AppSizes
  } = useMemo(
    () => ({
      content,
      config,
      debug,
      appSizes: {
        setRef,
        widthClassName,
        heightClassName
      }
    }),
    [content, config, debug, setRef, widthClassName, heightClassName]
  )

  const { styleContent } = config

  return (
    <PartnerContentContext.Provider value={value}>
      {(styleContent as string)?.length > 0 && <style>{styleContent}</style>}
      {isFulfilled(config.status) ? children : null}
    </PartnerContentContext.Provider>
  )
}

PartnerContentProvider.propTypes = {
  children: PropTypes.node.isRequired
}
