import { CONFIG } from "@meili/config"
import localeList from "@meili/lists/dist/json/localeList.json"
import { getListItemByValue } from "@meili/lists/dist/utils"

/**
 * List of all locales currently supported by date-fns library.
 * We need to update this list whenever they add a new locale (ie. library upgrade).
 * {@link} https://github.com/date-fns/date-fns/tree/v2.30.0/src/locale
 */
const DATE_FNS_SUPPORTED_LOCALE = [
  "af",
  "ar-DZ",
  "ar-EG",
  "ar-MA",
  "ar-SA",
  "ar-TN",
  "ar",
  "az",
  "be-tarask",
  "be",
  "bg",
  "bn",
  "bs",
  "ca",
  "cs",
  "cy",
  "da",
  "de-AT",
  "de",
  "el",
  "en-AU",
  "en-CA",
  "en-GB",
  "en-IE",
  "en-IN",
  "en-NZ",
  "en-US",
  "en-ZA",
  "eo",
  "es",
  "et",
  "eu",
  "fa-IR",
  "fi",
  "fr-CA",
  "fr-CH",
  "fr",
  "fy",
  "gd",
  "gl",
  "gu",
  "he",
  "hi",
  "hr",
  "ht",
  "hu",
  "hy",
  "id",
  "is",
  "it-CH",
  "it",
  "ja-Hira",
  "ja",
  "ka",
  "kk",
  "km",
  "kn",
  "ko",
  "lb",
  "lt",
  "lv",
  "mk",
  "mn",
  "ms",
  "mt",
  "nb",
  "nl-BE",
  "nl",
  "nn",
  "oc",
  "pl",
  "pt-BR",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sq",
  "sr-Latn",
  "sr",
  "sv",
  "ta",
  "te",
  "th",
  "tr",
  "ug",
  "uk",
  "uz-Cyrl",
  "uz",
  "vi",
  "zh-CN",
  "zh-HK",
  "zh-TW"
]

/**
 * Get language from locale.
 * For most locales we just split and take the first part but there are some exceptions.
 * For example, if locale is zh-Hans we need to use zh-Hans as language.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. en) if locale is not passed or not found
 */
export function getLanguage(locale?: string): string {
  const definedLocale = getListItemByValue(localeList, locale)
  const [defaultLanguage] = CONFIG.REACT_APP_DEFAULT_LOCALE.split("-")

  return definedLocale?.language ?? locale?.split("-")?.[0] ?? defaultLanguage
}

/**
 * Get country from locale.
 * For most locales we just split and take the second part but there are some exceptions.
 * For example, if locale is zh-Hans we need to use CN as country.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. GB) if locale is not passed or not found
 */
export function getCountry(locale?: string): string {
  const definedLocale = getListItemByValue(localeList, locale)
  const [, defaultCountry] = CONFIG.REACT_APP_DEFAULT_LOCALE.split("-")

  return definedLocale?.country ?? locale?.split("-")?.[1] ?? defaultCountry
}

/**
 * Get date locale from locale.
 * For most locales they are the same but there are some exceptions.
 * For example, if locale is no-NO we need to use nb as date locale.
 * Also need to check if the locale is in the date-fns library list.
 * For example, "de-DE" doesn't have a specific file but "de" does so we need to use the language.
 * Defaults to REACT_APP_DEFAULT_LOCALE (ie. en-GB) if locale is
 * is not passed or not found
 */
export function getDateLocale(locale?: string): string {
  const dateLocale =
    getListItemByValue(localeList, locale)?.dateLocale ?? locale
  if (DATE_FNS_SUPPORTED_LOCALE.includes(dateLocale)) {
    return dateLocale
  }

  // if locale is not supported we try language
  const language = getLanguage(locale)
  if (DATE_FNS_SUPPORTED_LOCALE.includes(language)) {
    return language
  }

  return language === "en" ? CONFIG.REACT_APP_DEFAULT_LOCALE : language
}

/**
 * Get fallback locales.
 * For most locales i18next can figure out the fallbacks but there are some exceptions.
 * For example, if locale is zh-CN we need to fallback to zh-Hans.
 */
export function getFallbackLocales(): Record<string, string[]> {
  return localeList.reduce(
    (locales, { value, fallback }) =>
      fallback ? { ...locales, [value]: fallback } : locales,
    {}
  )
}

/**
 * Check if language is currently supported by the app.
 */
export function isLanguageSupported(locale?: string) {
  if (!locale) return false

  const supportedLanguages =
    typeof CONFIG.REACT_APP_SUPPORTED_LANGUAGES === "string"
      ? CONFIG.REACT_APP_SUPPORTED_LANGUAGES.split(",")
      : CONFIG.REACT_APP_SUPPORTED_LANGUAGES

  return supportedLanguages.includes(locale)
}
